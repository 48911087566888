<template>
    <div class="join-group-page">
        <div v-if="isLoading" class="loading">
            <p>Processing invitation...</p>
        </div>
        <div v-else-if="errorMessage" class="error">
            <p>{{ errorMessage }}</p>
        </div>
        <div v-else class="success">
            <p>Joining group...</p>
        </div>
    </div>
</template>

<script>
import axios from '@/csrf/axios';

export default {
    name: 'JoinGroupPage',
    data() {
        return {
            isLoading: true,
            errorMessage: '',
        };
    },
    async created() {
        const { id, token } = this.$route.query;
        if (!id || !token) {
            this.errorMessage = 'Invalid invitation link';
            this.isLoading = false;
            return;
        }

        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

        if (!isLoggedIn) {
            // Redirect to login page with the current URL as the 'next' parameter
            const currentUrl = encodeURIComponent(window.location.href);
            this.$router.push(`/login?next=${currentUrl}`);
            return;
        }

        await this.joinGroup(id, token);
    },
    methods: {
        async joinGroup(groupId, token) {
            try {
                const response = await axios.post('/groups/accept/invite/', { token }, {
                    headers: { requiresAuth: true }
                });

                if (response.status === 200) {
                    // Redirect to the group page
                    this.$router.push(`/groups/${groupId}`);
                }
            } catch (error) {
                this.errorMessage = error.response?.data?.error || 'Failed to join group';
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.join-group-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading,
.error,
.success {
    text-align: center;
    font-size: 1.2rem;
}

.error {
    color: red;
}
</style>