// @ts-nocheck
// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/components/Login.vue'; // Adjust the path as necessary to where your Login.vue component is stored
import Home from '@/components/Home.vue';
import SignUp from '@/components/SignUp.vue';
import ForgotPassword from '@/components/ForgotPassword.vue';
import ResetPassword from '@/components/ResetPassword.vue';
import AboutUs from '@/components/AboutUs.vue';
import Privacy from '@/components/Privacy.vue';
import Terms from '@/components/Terms_and_Conditions.vue';
import NotFoundPage from '@/components/404_Page.vue';
import DeleteUserFileFolders from '@/components/DeleteUserFilesFolders.vue';
import Pricing from '@/components/PricingPlans.vue';
import EditProfile from '@/components/dashboard/EditProfile.vue';
import ContentManager from '@/components/dashboard/views/ContentManager.vue';
import JoinGroup from '@/components/dashboard/JoinGroup.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Yugin'
    }
  },
  {
    path: '/about',
    name: 'AboutUs',
    component: AboutUs,
    meta: {
      title: 'About Us'
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: {
      title: 'Sign Up'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'Forgot Password'
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'Reset Password'
    }
  },

  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      title: 'Privacy'
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms',
    component: Terms,
    meta: {
      title: 'Terms and Conditions'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundPage,
    meta: {
      title: 'Page Not Found'
    }
  },
  {
    path: '/delete/user-data',
    name: 'DeleteUserFileFolders',
    component: DeleteUserFileFolders,
    meta: {
      title: 'User Data',
      requiresAuth: true

    }
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
    meta: {
      title: 'Pricing',
    }
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: EditProfile,
    meta: {
      title: 'Edit Profile',
    },
  },
  {
    path: '/my-hub',
    name: 'MyHub',
    component: ContentManager,
    meta: {
      title: 'My Hub',
      requiresAuth: true
    }
  },
  {
    path: '/groups/:id',
    name: 'GroupContent',
    component: ContentManager,
    meta: {
      title: 'Group Content',
      requiresAuth: true
    }
  },
  {
    path: '/folder/:id',
    name: 'FolderContent',
    component: ContentManager,
    meta: {
      title: 'Folder Content',
      requiresAuth: true
    }
  },
  {
    path: '/join-group',
    name: 'Join Group',
    component: JoinGroup,
    meta: {
            requiresAuth: true

    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Page Title'; // Sets the page title based on the route meta
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('isLoggedIn') !== 'true') {
      next({
        path: '/login',
        query: { next: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;